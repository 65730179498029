
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import {MerchantsListAPI} from "@/common/api/merchantManage/merchantsList";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { utils } from "xlsx";
import gallery from "@/components/gallery.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  // shareShopTag2ch,
  chooseShopTagList
} from '@/common/config';

import amap from "@/components/amap.vue";
export const pageApiList = getPageAPIs(MerchantsListAPI);

const defaultStoreinfo: any = {
  shop_type: 1,
  brand_type:1,
  merchant_id: "",
  name: "",
  address: "",
  lat: "",
  long: "",
  contact_phone: "",
  spread_image: "",
  is_status: 4,
  sub_account: "",
  pool_table_map: "",
  fengcheng_rule: "",

  auto_withdraw: 1, // 1 表示  每周日自动申请提现； 0 表示 关闭

  shop_member: 2, // 1 关闭； 2 开启


  shop_mode: 1, // 店铺类型  1普通 2品牌 3旗舰
  zkb_user_id: '',
  lock_account: "",
  lock_password: "",
  sanyou_account: "", // 闪优物联账号
  sanyou_password: "",

  is_has_order: 0, // 是否有订单开门 1是 0否
  meituan_divide: 1, // 美团抖音劵抽成 1是 0否
  main_door: '', // 绑定门禁设备编号  多个编号以英文逗隔开（如：编号1,编号2）
}

const defaultStoreStatusList: any[] = [
  {
    label: '待审核',
    value: 1
  },
  {
    label: '上架',
    value: 2
  },
  {
    label: '下架',
    value: 3
  },
  {
    label: '装修中',
    value: 4
  }
]

@Component({
  components: {
    amap,
    gallery,
  },
})
export default class MerchantsList extends Vue {
  private form: any = {
    time: [],
  };
  private mcshow: boolean = false;
  private mcinfo: any = {};
  private front_card: any = [];
  private back_card: any = [];
  private business_license: any = [];
  private storeshow: boolean = false;
  private storeinfo: any = {

  };
  private turnover: number = 0;
  private rule_top: number = 0;
  private rule_up: number = 0;
  private is_status: boolean = false;

  private shopTypeList: any[] = [
    {
      label: '台球',
      value: 1
    },
    {
      label: '棋牌',
      value: 2
    },
    // {
    //   label: '台球 + 棋牌',
    //   value: 3
    // }
  ]

  private brandTypeList: any[] = [
    {
      label: "熊猫",
      value: 1,
    },
    {
      label: "麦力士",
      value: 2,
    },
  ];

  private shopTagList: any[] = [
    ...chooseShopTagList
  ]

  private sub_account: any = [
    {
      value: "",
      nickname: "",
    },
  ];
  private mapshow: boolean = false;
  private merchantApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.merchantApi = new MerchantsListAPI();

    this.deepLoopUtil = new DeepLoop();
  }

  private firstLoading: boolean = true;

  private dialogVisibleFormm: boolean = false;
  private dialogVisible: boolean = false;
  private dialogImageUrl: string = "";

  private disabled: boolean = false;
  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.merchantApi.getMerchantTableList(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }
  private add(index: number): void {
    this.storeinfo.merchant_user.push({ mobile: "", nickname: "" });
  }
  private delete_account_id: any = [];
  private remove(index: number): void {
    if (this.storeinfo.merchant_user[index].id != undefined) {
      this.delete_account_id.push(this.storeinfo.merchant_user[index].id);
    }
    this.storeinfo.merchant_user.splice(index, 1);
    if (this.storeinfo.merchant_user.length == 0) {
      this.storeinfo.merchant_user.push({ mobile: "", nickname: "" });
    }
  }

  private get hasLockInfo() {
    const storeinfo = this.storeinfo

    const {
      shop_type
    } = storeinfo || {}

    const allowHasLockTypes: number[] = [2, 3]

    return allowHasLockTypes.includes(shop_type)
  }

  private get hasDeskBallInfo() {
    const storeinfo = this.storeinfo

    const {
      shop_type
    } = storeinfo || {}

    const allowHasDeskTypes: number[] = [1, 3]

    return allowHasDeskTypes.includes(shop_type)
  }

  private storeStatusList: any[] = [
    ...(defaultStoreStatusList || [])
  ]

  // 仅仅是 台球店铺
  private get onlyPandaBallShop() {
    const storeinfo = this.storeinfo

    const {
      shop_type
    } = storeinfo || {}

    const allowHasLockTypes: number[] = [1]

    return allowHasLockTypes.includes(shop_type)
  }

  private changeis_status(): void {
    if (this.is_status) {
      this.storeinfo.is_status = 2;
    } else {
      this.storeinfo.is_status = 1;
    }
  }
  // private shop_member: number = 1;
  // private changeshop_member(): void {
  //   if (this.shop_member) {
  //     this.storeinfo.shop_member = 2;
  //   } else {
  //     this.storeinfo.shop_member = 1;
  //   }
  // }
  private isWatch: boolean = true;
  private getAddress(regeocode: any, lat: string, log: string): void {
    this.isWatch = false;
    this.storeinfo.city =
      regeocode.addressComponent.city || regeocode.addressComponent.district;
    this.storeinfo.address = regeocode.formattedAddress;
    this.storeinfo.cityCode = regeocode.addressComponent.citycode;
    this.storeinfo.lat = log;
    this.storeinfo.long = lat;
    this.$nextTick(() => {
      this.isWatch = true;
    });
  }
  private addAddress() {
    console.log(1);
    // this.restForms();
    const amap: any = this.$refs.amap;
    try {
      amap.placeSearch.clear();
    } catch (e) {
      amap.callbackList.push(amap.getAddressInMap);
    }
    // this.storeinfo = { ...data };
    this.mapshow = true;
    this.$nextTick(() => {
      const amap: any = this.$refs.amap;
      try {
        amap.getAddressInMap();
      } catch (e) {
        amap.callbackList.push(amap.getAddressInMap);
      }
    });
  }
  private setCity(city: any) {
    console.log(city);
  }
  private type: number = 0;
  private mcadd(): void {
    this.type = 1;
    this.mcinfo = new Util().clearObject(this.mcinfo);
    this.front_card = [];
    this.back_card = [];
    this.business_license = [];
    this.mcshow = true;
  }

  private addstore(item: any): void {
    this.storeinfo = {
      ...this.deepLoopUtil.deepCopy(defaultStoreinfo, false),
    };
    this.storeinfo.merchant_id = item.id;

    console.log(this.storeinfo, 'this.storeinfo')
    this.turnover = 0;
    this.rule_top = 0;
    this.rule_up = 0;
    this.is_status = false;
    this.sub_account = [
      {
        nickname: "",
        value: "",
      },
    ];
    this.shop_picture = [];
    this.pool_table_maps = [];
    this.storeinfo.is_status = 4;
    this.storeshow = true;
  }

  private tostore(id: string): void {
    this.$router.push({
      path: "/rechargeRecord",
      query: {
        merchant_id: id,
      },
    });
  }

  // 查看商户技师
  private handleLookMerchantTechnicianDetail(merchantItem: any) {
    this.merchantApi.getMerchantDetailInfo(
      { merchant_id: merchantItem.id },
      (res: any) => {
        this.type = 2;
        this.mcshow = true;
        this.mcinfo = res.data;
        this.mcinfo.merchant_id = merchantItem.id;
        this.mcinfo.mobile = res.data.mobile;
        this.mcinfo.full_name = res.data.full_name;
        // this.mcinfo.id_card = res.data.id_card;
        // this.front_card = [{ url: res.data.front_card_url }];
        // this.back_card = [{ url: res.data.back_card_url }];
        // this.business_license = [{ url: res.data.business_license_url }];
      }
    );
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private merchantExcelList2ch: any = {
    merchantName: "商户名",
    proofCode: "营业执照ID",
    mobile: "联络电话",
    price: "收入",
    orderNum: "完成单数",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "merchantName",
    "proofCode",
    "mobile",
    "price",
    "orderNum",
  ];

  // 点击导出excel 表格
  private handleExportExcelMerchantList() {
    this.getTable();
    const params: any = this.tableData;

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.merchantApi.exportExcelMerchantList(
      params,
      (res: any) => {
        const { startDate, endDate } = params || {};

        const filename = `商户列表 ${startDate}到${endDate}.xlsx`;
        const { data: originList } = res || {};

        // const {
        //   list: originList
        // } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              newItem.orderNum =
                typeof orderNum == "number"
                  ? orderNum.toString()
                  : orderNum || "";

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
  private hideUpload1: boolean = false;
  private hideUpload2: boolean = false;
  private hideUpload3: boolean = false;
  private hideUpload4: boolean = false;
  private hideUpload5: boolean = false;
  private limitCount: Number = 1;
  private fileList: any[] = [];
  private commonApi = new CommonApi();
  private getOss(file: File, cb: Function) {
    console.log(file);
    if (file.type == "image/jpeg" || file.type == "image/png") {
      this.commonApi.getOss(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    } else {
      this.commonApi.getOssvideo(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    }
  }
  private fileChange1(file: any, fileList: any[]): void {
    this.hideUpload1 = fileList.length >= this.limitCount;
    this.front_card = fileList;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      this.mcinfo.front_card = res.data.data.item.id;
    });
  }
  private fileChange2(file: any, fileList: any[]): void {
    this.hideUpload2 = fileList.length >= this.limitCount;
    this.back_card = fileList;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      this.mcinfo.back_card = res.data.data.item.id;
    });
  }
  private fileChange3(file: any, fileList: any[]): void {
    this.hideUpload3 = fileList.length >= this.limitCount;
    this.business_license = fileList;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      this.mcinfo.business_license = res.data.data.item.id;
    });
  }
  private shop_picture: any = [];
  private fileChange4(file: any, fileList: any[]): void {
    this.hideUpload4 = fileList.length >= 20;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      const raw = res.data.data.item;
      let obj: any = {
        id: "",
        title: "",
        type: "",
        href: "",
        types: "",
      };
      if (raw.type == 2) {
        obj.type = "video/mp4";
        obj.types = "2";
        obj.id = raw.id;
        obj.url = raw.url;
        obj.href = raw.url;
        obj.poster =
          raw.url +
          "?spm=a2c4g.11186623.2.1.yjOb8V&x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast";
      } else {
        obj.types = "1";
        obj.type = "image/jpeg";
        obj.id = raw.id;
        obj.url = raw.url;
        obj.href = raw.url;
      }
      console.log(obj);
      this.shop_picture.push(obj);
    });
  }
  private pool_table_maps: any = [];
  private fileChange5(file: any, fileList: any[]): void {
    this.hideUpload5 = fileList.length >= 5;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      const raw = res.data.data.item;
      let obj: any = {
        id: raw.id,
        url: raw.url,
      };
      this.pool_table_maps.push(obj);
    });
  }
  //移除
  private handleRemove1(file: any): void {
    this.hideUpload1 = false;
    this.front_card = [];
    this.mcinfo.front_card = "";
  }
  private handleRemove2(file: any): void {
    this.hideUpload2 = false;
    this.back_card = [];
    this.mcinfo.back_card = "";
  }
  private handleRemove3(file: any): void {
    this.hideUpload3 = false;
    this.business_license = [];
    this.mcinfo.business_license = "";
  }
  private handleRemove4(file: any): void {
    this.hideUpload4 = false;
    for (let i in this.shop_picture) {
      if (this.shop_picture[i].id == file.id) {
        this.shop_picture.splice(i, 1);
      }
    }
  }
  private handleRemove5(file: any): void {
    this.hideUpload5 = false;
    for (let i in this.pool_table_maps) {
      if (this.pool_table_maps[i].id == file.id) {
        this.pool_table_maps.splice(i, 1);
      }
    }
  }
  //预览
  private handlePictureCardPreview(file: any): void {
    this.dialogVisible = true;
    this.dialogImageUrl = file.url;
  }
  //下载
  private handleDownload(file: any): void {
    console.log(file);
  }
  private handleClose(done: any): void {
    this.$confirm("确认关闭？")
      .then((_) => {
        this.mcinfo = new Util().clearObject(this.mcinfo);
        done();
      })
      .catch((_) => {});
  }

  //新增
  private addmc(): void {
    if (this.type == 1) {
      this.merchantApi.addNewMerchantInfo(
        this.mcinfo,
        (res: any) => {
          this.mcshow = false;
          Util.showToast("新增成功");
        },
        () => {}
      );
    } else {
      this.merchantApi.updateMerchantDetailInfo(
        this.mcinfo,
        (res: any) => {
          this.mcshow = false;
          Util.showToast("修改成功");
        },
        () => {}
      );
    }
  }

  private changeMerchantInfoLoading: Boolean = false

  private substore(): void {
    this.storeinfo.fengcheng_rule =
      this.turnover + "=" + this.rule_up + "," + this.rule_top;
    this.storeinfo.sub_account = "";
    for (let i of this.sub_account) {
      this.storeinfo.sub_account += i.value + "=" + i.nickname + ",";
    }
    this.storeinfo.sub_account = this.storeinfo.sub_account.slice(
      0,
      this.storeinfo.sub_account.length - 1
    );

    if (!this.hasLockInfo) {
      this.storeinfo.pool_table_map = "";
      // for (let i of this.pool_table_maps) {
      //   this.storeinfo.pool_table_map += i.id + ",";
      // }
      // this.storeinfo.pool_table_map = this.storeinfo.pool_table_map.slice(
      //   0,
      //   this.storeinfo.pool_table_map.length - 1
      // );

      this.storeinfo.pool_table_map =  (this.storeinfo.pool_table_maps || []).map((item: any) => item.id).join(',')

      delete this.storeinfo.is_has_order

   } else {
      delete this.storeinfo.pool_table_map;
    }

    if (!this.hasDeskBallInfo) {
      delete this.storeinfo.shop_mode
    }


    this.storeinfo.spread_image = "";
    for (let i of this.shop_picture) {
      this.storeinfo.spread_image += i.id + "=" + i.types + ",";
    }
    this.storeinfo.spread_image = this.storeinfo.spread_image.slice(
      0,
      this.storeinfo.spread_image.length - 1
    );

    // if (this.changeMerchantInfoLoading) return

    this.changeMerchantInfoLoading = true

    this.merchantApi.addStore(this.storeinfo, (res: any) => {
      this.storeshow = false;

      this.changeMerchantInfoLoading = false
      Util.showToast("添加成功");
      this.search();
    }, () => {
      this.changeMerchantInfoLoading = false
    });
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
  private images: any = [];
  private preview(file: any): void {
    this.images = [file];
  }
}
